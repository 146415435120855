import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { useBreakpoints } from '@amzn/stencil-react-components/responsive';
import { Radio, Checkbox, RadioColorScheme, CheckboxColorScheme } from '@amzn/stencil-react-components/form';
import {
  IconAlertCircleFill,
  IconCheck,
  IconCross,
  IconSize,
  IconArrowRight,
  IconArrowLeft,
} from '@amzn/stencil-react-components/icons';
import PlayerScreen from './PlayerScreen';
import './ModulePage.css';
import { ProgressBar, Status } from '@amzn/stencil-react-components/progress-bar';
import { View } from '@amzn/stencil-react-components/layout';
import constants from '../../Utils/Constants';
import useArb from '../../hooks/useArb';
import { LiveRegion, LiveRegionRole } from '@amzn/stencil-react-components/live-announcer';

interface IModule {
  moduleId: string;
  moduleName: string;
  description: string;
  resourceUrl: string;
  resourceUrlMobile: string;
  resourceAltText: string;
  contents: Array<IContent>;
  module_completed: boolean;
  status: string;
  Index?: number;
}

interface IContent {
  answer: any;
  contentData: Array<string>;
  contentId: string;
  contentName: string;
  contentType: string;
  description: string;
  question_completed: boolean;
  status: string;
  Index?: number;
  input_value?: string;
}

interface IModuleProps {
  state: Array<IModule>;
  activeMenu: IModule;
  activeSubMenu: IContent;
  updateModuleStatus: Function;
  goBack: Function;
}

export default function ModulePage(props: IModuleProps) {
  const { t } = useArb('appStrings');

  const { matches: deviceResolution } = useBreakpoints();
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [isClicked, setIsClicked] = useState<string[]>([]);
  const [isWrong, setIsWrong] = useState<string[]>([]);
  const [isCorrect, setIsCorrect] = useState<string[]>([]);
  const [activeMenu, setActiveMenu] = useState(props.activeMenu);
  const [activeSubMenu, setActiveSubMenu] = useState(props.activeSubMenu);
  const [isBrokenImage, setIsBrokenImage] = useState(false);

  useEffect(() => {
    if (props.activeMenu?.moduleId) {
      setIsCorrect([]);
      setIsClicked([]);
      let tsubmenu = props.activeSubMenu;
      if (props.activeSubMenu && props.activeSubMenu.status === constants.PHO.STATUS.COMPLETED) {
        if (props.activeSubMenu['input_value']) {
          setIsCorrect([props.activeSubMenu.input_value]);
          setIsClicked([props.activeSubMenu.input_value]);
        } else {
          setIsCorrect(props.activeSubMenu.answer);
          setIsClicked(props.activeSubMenu.answer);
        }
      } else if (tsubmenu && props.activeSubMenu.question_completed) {
        tsubmenu.question_completed = false;
      }
      setActiveMenu(props.activeMenu);
      setActiveSubMenu(tsubmenu);
    }
  }, [props.activeMenu, props.activeSubMenu]);

  const chooseAnswer = (value: string) => {
    setWrongAnswer(false);
    setIsWrong([]);
    setIsCorrect([]);
    setIsClicked([value]);
  };

  // Function to handle multiple answer selection
  const chooseMultiAnswers = (value: string) => {
    setWrongAnswer(false);
    // If the selected value has already been clicked, deselect it. Otherwise, select it.
    setIsClicked((prev) => (prev.includes(value) ? prev.filter((option) => option !== value) : [...prev, value]));
  };

  // Function to check the selected answer(s)
  const checkAnswer = () => {
    const { contentType, answer } = activeSubMenu;
    let isCorrect = false;
    let correctAnswers: string[] = [];
    let wrongAnswers: string[] = [];

    if (contentType === constants.INPUT_TYPES.USER_INPUT_SINGLE_SELECT) {
      isCorrect = true;
      correctAnswers = isClicked;
    } else if (contentType === constants.INPUT_TYPES.SINGLE_SELECT) {
      isCorrect = answer.includes(isClicked[0]);
      correctAnswers = isCorrect ? isClicked : [];
      wrongAnswers = isCorrect ? [] : isClicked;
    } else {
      // Multiple select
      isCorrect = isClicked.every((ans) => answer.includes(ans)) && isClicked.length === answer.length;
      correctAnswers = isClicked.filter((ans) => answer.includes(ans));
      wrongAnswers = isClicked.filter((ans) => !answer.includes(ans));
    }

    setWrongAnswer(!isCorrect);
    setIsWrong(wrongAnswers);
    setIsCorrect(correctAnswers);
    setActiveSubMenu((prev) => ({ ...prev, question_completed: isCorrect }));
  };

  const goNext = () => {
    let userInput = '';
    if (activeSubMenu && activeSubMenu.contentType.includes('USER_INPUT')) {
      userInput = activeSubMenu.contentData.findIndex((ans) => ans === isClicked[0]) + '';
    }
    setIsClicked([]);
    setIsCorrect([]);
    setIsWrong([]);
    props.updateModuleStatus(userInput);
  };

  const resetState = () => {
    setIsClicked([]);
    setIsCorrect([]);
    setIsWrong([]);
    setWrongAnswer(false);
    resetInputs();
  };

  // TODO: BAD PRACTICE... Do not manipulate DOM directly!
  const resetInputs = () => {
    const inputs = document.querySelectorAll('input');
    inputs.forEach((input: HTMLInputElement) => {
      input.disabled = false;
      input.checked = false;
    });
  };

  const goBack = () => {
    resetState();
    props.goBack();
  };

  const showFormError = () => {
    setWrongAnswer(true);
  };

  return (
    <View
      style={{
        width: deviceResolution.l || deviceResolution.xl ? '74%' : '100%',
        height: '100%',
        background: 'white',
        zIndex: 5,
        paddingBottom: deviceResolution.l || deviceResolution.xl ? '0px' : '70px',
      }}
      className="sidenav"
    >
      <Col
        width={'100%'}
        gridGap={'S400'}
        style={{
          padding: deviceResolution.l || deviceResolution.xl ? '2.5rem 8rem' : '1rem 1rem',
          paddingBottom: '100px',
        }}
      >
        {(deviceResolution.l || deviceResolution.xl) && (
          <Col width="100%">
            <Row width="100%">
              <Text fontSize="T300" fontWeight="regular" color="neutral90">
                {activeMenu?.moduleName}{' '}
                {activeSubMenu
                  ? `/ ${activeSubMenu.contentName} ${activeSubMenu.Index ? activeSubMenu.Index + 1 : 1}`
                  : ''}
              </Text>
            </Row>
            <Col width="25%" margin="10px 0" id="progress-bar">
              <ProgressBar
                aria-labelledby="status-indicator-1"
                progress={activeMenu?.Index ? (activeMenu.Index - 1) / (props.state.length - 1) : 0}
                status={Status.Positive}
              />
            </Col>
          </Col>
        )}
        <Row width="100%" height="auto" id="content-image">
          {
            //We are hiding player screen from the feedback module
            props.state &&
            props.state[props.state.length - 1]?.moduleId !== activeMenu?.moduleId &&
            activeMenu?.resourceUrl !== undefined ? (
              activeMenu?.resourceUrl.split('.')[1] === 'mp4' ? (
                <PlayerScreen
                  path={
                    deviceResolution.l || deviceResolution.xl ? activeMenu.resourceUrl : activeMenu.resourceUrlMobile
                  }
                  altText={activeMenu.resourceAltText}
                />
              ) : (
                <Container alignItems="center" style={{ marginTop: '10px' }}>
                  {isBrokenImage ? (
                    <Text fontSize={'T200'} fontWeight="regular" color="neutral90">
                      {activeMenu.resourceAltText
                        ? activeMenu.resourceAltText
                        : t(
                            'PHO-error-image-not-found',
                            'Unable to show the img: Kindly reach out to the recruiter from the staffing agency to obtain this information'
                          )}
                    </Text>
                  ) : (
                    <img
                      src={`${sessionStorage.getItem('cloudfrontS3Distribution')}/${
                        deviceResolution.l || deviceResolution.xl
                          ? activeMenu.resourceUrl
                          : activeMenu.resourceUrlMobile
                      }`}
                      onError={() => {
                        setIsBrokenImage(true);
                      }}
                      alt={
                        activeMenu.resourceAltText
                          ? activeMenu.resourceAltText
                          : t(
                              'PHO-error-image-not-found',
                              'Unable to show the img: Kindly reach out to the recruiter from the staffing agency to obtain this information'
                            )
                      }
                      style={{ width: '100%' }}
                    />
                  )}
                </Container>
              )
            ) : (
              <></>
            )
          }
        </Row>
        {(activeSubMenu?.description || activeMenu?.description) && (
          <Row>
            <Text>
              {activeSubMenu
                ? activeSubMenu.description
                : activeMenu.description
                ? t('PHO-note', 'Note: ', { information: activeMenu.description, b: (chunk: any) => chunk })
                : ''}
            </Text>
          </Row>
        )}
        <Col gridGap="S200" id="OptionsField">
          {activeSubMenu &&
            activeSubMenu.contentData.map((current: any, index: number) => (
              <Row
                key={index}
                style={{ cursor: 'pointer' }}
                justifyContent="space-between"
                border="2px solid #8B96A3"
                padding="S200"
                className={
                  isClicked.includes(current)
                    ? isCorrect.includes(current)
                      ? 'correct-tab'
                      : isWrong.includes(current)
                      ? 'wrong-tab'
                      : 'selected-tab'
                    : ''
                }
                onClick={() =>
                  activeSubMenu.contentType === constants.INPUT_TYPES.MULTI_SELECT
                    ? chooseMultiAnswers(current)
                    : chooseAnswer(current)
                }
              >
                <View style={{ width: '92%' }}>
                  <Text
                    fontSize={'T200'}
                    id={'label-option-' + index}
                    className={
                      isClicked.includes(current)
                        ? isCorrect.includes(current)
                          ? 'correct-label'
                          : isWrong.includes(current)
                          ? 'wrong-label'
                          : 'selected-label'
                        : ''
                    }
                  >
                    {current}
                  </Text>
                </View>
                {activeSubMenu.contentType === constants.INPUT_TYPES.MULTI_SELECT
                  ? !isCorrect.includes(current) &&
                    !isWrong.includes(current) && (
                      <Checkbox
                        onChange={() => {}}
                        id={'checkbox-option-' + index}
                        data-testid={'checkbox-option-' + index}
                        disabled={activeSubMenu.question_completed ? true : false}
                        checked={isClicked.includes(current) ? true : false}
                        key={'checkbox-option-' + index}
                        name={'question' + index}
                        colorScheme={
                          isClicked.includes(current) ? CheckboxColorScheme.Inverted : CheckboxColorScheme.Default
                        }
                        value={current}
                      />
                    )
                  : !isCorrect.includes(current) &&
                    !isWrong.includes(current) && (
                      <Radio
                        onChange={() => {}}
                        id={'radio-option-' + index}
                        data-testid={'radio-option-' + index}
                        disabled={activeSubMenu.question_completed ? true : false}
                        checked={isClicked.includes(current) ? true : false}
                        key={'radio-option-' + index}
                        name={'question' + index}
                        colorScheme={isClicked.includes(current) ? RadioColorScheme.Inverted : RadioColorScheme.Default}
                        value={current}
                      />
                    )}
                {isCorrect.includes(current) && (
                  <div>
                    <IconCheck size={IconSize.Medium} color="white" aria-hidden="false" aria-label="Correct Answer" />
                  </div>
                )}
                {isWrong.includes(current) && (
                  <div>
                    <IconCross size={IconSize.Small} color="white" aria-hidden="false" aria-label="Wrong Answer" />
                  </div>
                )}
              </Row>
            ))}
        </Col>

        <LiveRegion aria-live="polite" role={LiveRegionRole.Status}>
          {wrongAnswer && (
            <Row
              style={{ background: '#fef0ef', padding: '10px 5px', borderLeft: '8px solid #BB2929' }}
              id="incorrect-answer-banner"
            >
              <Spacer height="S300" />
              <IconAlertCircleFill size={IconSize.Small} color="#BB2929" margin="4px 5px 0 0" />
              <Text>
                <span style={{ color: '#BB2929', fontWeight: 'bold' }}>{t('PHO-form-input-error', 'Incorrect')},</span>{' '}
                {t('PHO-form-input-error-prompt', 'Please choose the appropriate answer.')}
              </Text>
            </Row>
          )}
        </LiveRegion>

        <Row
          justifyContent="space-between"
          style={{ marginTop: deviceResolution.l || deviceResolution.xl ? '16px' : '0px' }}
        >
          {activeMenu.Index && activeMenu.Index > 1 && (
            <Button
              variant={ButtonVariant.Secondary}
              style={{ padding: '0.5rem 3.5rem', paddingLeft: '3rem', fontWeight: 'normal' }}
              onClick={goBack}
              id="back-button"
            >
              {t('PHO-btn-back', 'Back')}
            </Button>
          )}
          {activeSubMenu ? (
            <Button
              variant={ButtonVariant.Primary}
              style={{ padding: '0.5rem 3.5rem', paddingRight: '3rem', fontWeight: 'normal' }}
              onClick={
                // Disabled state: Allow people who rely on assistive technology to run into error messages
                !activeSubMenu.question_completed && isClicked.length === 0
                  ? showFormError
                  : activeSubMenu.question_completed
                  ? goNext
                  : isWrong.length > 0
                  ? resetState
                  : checkAnswer
              }
              id="continue-button"
            >
              {activeSubMenu.question_completed ? (
                <Row>
                  {t('PHO-btn-next', 'Next')}
                  <IconArrowRight
                    display="inline"
                    margin={{ left: 10, top: 4 }}
                    size={IconSize.ExtraSmall}
                    rtl={IconArrowLeft}
                  />
                </Row>
              ) : isWrong.length > 0 ? (
                t('PHO-btn-reset', 'Reset')
              ) : (
                t('PHO-btn-continue', 'Continue')
              )}
            </Button>
          ) : (
            <Button
              variant={ButtonVariant.Primary}
              onClick={goNext}
              style={{ padding: '0.5rem 3.5rem', paddingRight: '3rem', fontWeight: 'normal' }}
              id="next-button"
            >
              <Row>
                {t('PHO-btn-next', 'Next')}
                <IconArrowRight
                  display="inline"
                  margin={{ left: 10, top: 4 }}
                  size={IconSize.ExtraSmall}
                  rtl={IconArrowLeft}
                />
              </Row>
            </Button>
          )}
        </Row>
      </Col>
    </View>
  );
}
