import { Row, ViewProps } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import MenuIcon from './MenuIcon';
import { styledWithTheme } from '@amzn/stencil-react-components/context';

interface ContentItemProps extends Omit<ViewProps, 'content'> {
  content: IContent;
  isActive: boolean;
  index: number;
}

// TODO: Similar to MenuItem's StyledRow
const StyledRow = styledWithTheme(Row)<{ isActive: boolean }>(
  ({
    theme: {
      selectors: { color },
    },
    isActive,
  }) => ({
    height: '56px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1.5rem',
    backgroundColor: isActive ? color('primary70') : color('neutral05'),
  })
);

export default function SubMenuItem({ content, isActive, index, ...props }: ContentItemProps) {
  return (
    <StyledRow isActive={isActive} {...props}>
      <Text fontSize="T200" color={isActive ? 'neutral0' : 'neutral90'}>
        {content.contentName}
        {' ' + (index + 1)}
      </Text>
      <MenuIcon status={content.status} />
    </StyledRow>
  );
}
