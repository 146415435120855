import React, { useEffect, useState } from 'react';
import { Col, Flex, Row, View } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { useBreakpoints } from '@amzn/stencil-react-components/responsive';
import SideNavBar from '../../Components/SideNavBar';
import FooterImgSVG from '../../assets/FotterImg.svg';
import { IconChevronDown, IconChevronUp } from '@amzn/stencil-react-components/icons';
import { ProgressBar, Status } from '@amzn/stencil-react-components/progress-bar';
import ModulePage from '../Modules/ModulePage';
import { PhoService } from '../../Services/PhoServices';
import { MessageBanner } from '@amzn/stencil-react-components/message-banner';
import constants from '../../Utils/Constants';
import FullPageCenteredSpinner from '../CommonComponents/FullPageCenteredSpinner';
import { sortModulesOnPriority } from '../../Utils/Util';
import ConfirmComponent from '../CommonComponents/ConfirmComponent';
import { withAlertSnackBar } from '../../Components/MessageBanner/AlertSnackBarHOC';
import { Base64 } from 'js-base64';
import useArb from '../../hooks/useArb';

interface IHomeProps {
  snackbarShowMessage: Function;
}

function HomePage(props: IHomeProps) {
  const { t, locale } = useArb('appStrings');

  const phoId: any = sessionStorage.getItem('phoId');
  const candidateId: any = sessionStorage.getItem('candidateId');
  const applicationId: any = sessionStorage.getItem('applicationId');
  const candidateName: any = sessionStorage.getItem('candidateName')
    ? sessionStorage.getItem('candidateName')
    : 'Candidate';
  const { matches: deviceResolution } = useBreakpoints();
  const [moduleData, setModuleData] = useState([{}]);
  const [menuItems, setMenuItems] = useState<Array<IModule>>([]);
  const [activeMenu, setActiveMenu] = useState<any>(null);
  const [activeSubMenu, setActiveSubMenu] = useState<any>(null);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState<boolean>(false);
  const [enableRestart, setEnableRestart] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [enableConfirm, setEnableConfirm] = useState(false);

  const getData = () => {
    setIsLoading(true);
    PhoService.getModulesByPhoId({ phoId: phoId, locale: locale.replace('-', '_') })
      .then((res: any) => {
        if (res.data?.modules.length) {
          getProgress(sortModulesOnPriority(res.data.modules));
        } else {
          setIsLoading(false);
          props.snackbarShowMessage(t('PHO-error-modules-empty', `Modules empty`), 'error');
        }
      })
      .catch((err: any) => {
        props.snackbarShowMessage(`${err?.data?.message}`);
        setIsLoading(false);
      });
  };

  // TODO: Update all functions to use useCallback
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const reset = () => {
    setIsLoading(true);
    PhoService.resetPhoProgress({ phoId: phoId, candidateId: candidateId, applicationId: applicationId })
      .then((res) => {
        let hiringStagebody = {
          candidatesInput: [{ id: applicationId }].map((candidate) => {
            return { candidateId: candidate.id, hiringStage: constants.PHO.STAGES.ASSIGNED };
          }),
          modifiedBy: candidateId,
          actionType: constants.PHO.UPDATE_CANDIDATE_ENTITY_OPERATION,
        };

        PhoService.postCandidateHiringStage(hiringStagebody)
          .then((res) => {
            setEnableConfirm(false);
            setEnableRestart(false);
            setIsLoading(false);
            getData();
          })
          .catch((err) => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onComplete = () => {
    setIsLoading(true);
    PhoService.markPhoComplete({
      phoId: phoId,
      candidateId: candidateId,
      applicationId: applicationId,
    })
      .then((res) => {
        let hiringStagebody = {
          candidatesInput: [{ id: applicationId }].map((candidate) => {
            return { candidateId: candidate.id, hiringStage: constants.PHO.STAGES.COMPLETED };
          }),
          modifiedBy: candidateId,
          actionType: constants.PHO.UPDATE_CANDIDATE_ENTITY_OPERATION,
        };

        PhoService.postCandidateHiringStage(hiringStagebody)
          .then((res) => {
            setEnableConfirm(true);
            setActiveMenu(null);
            setActiveSubMenu(null);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getProgress = (responseModules: any) => {
    PhoService.getCandidatePhoProgress({ phoId: phoId, candidateId: candidateId, applicationId: applicationId })
      .then((res: any) => {
        let ids: any = [];
        let userInputIds: any = [];
        if (res?.data?.phoStatus === constants.PHO.STATUS.COMPLETED) {
          setEnableRestart(true);
        }
        if (res?.data?.phoStatus) {
          ids = res?.data?.phoProgress.substr(1, res?.data?.phoProgress.length - 2);
          userInputIds = ids.split(',').filter((id: string) => id.indexOf(constants.CONTENT_PREFIX) !== -1);
          ids = ids
            .split(/[,[\]=]/)
            .map((item: string) => item.trim())
            .filter((item: string) => item.indexOf(constants.CONTENT_PREFIX) === -1);
        }
        let modules: Array<IModule> = [
          {
            moduleId: constants.CUSTOM_MODULES.PHO_CUSTOM_WELCOME_MODULE,
            moduleName: t('PHO-welcome-module-title', 'Welcome'),
            description: '',
            resourceUrl: '',
            resourceUrlMobile: '',
            contents: [],
            resourceAltText: '',
            module_completed: ids.length ? true : false,
            priority: '0',
            status: ids.includes(constants.CUSTOM_MODULES.PHO_CUSTOM_WELCOME_MODULE)
              ? constants.PHO.STATUS.COMPLETED
              : constants.PHO.STATUS.IN_PROGRESS,
            Index: 0,
          },
        ];
        setActiveSubMenu(null);
        if (modules[0].status === constants.PHO.STATUS.IN_PROGRESS) {
          setActiveMenu(modules[0]);
        }

        responseModules.forEach((item: IModule, index: number) => {
          item['Index'] = index + 1;
          item['module_completed'] = ids.includes(item.moduleId);
          item['status'] = ids.includes(item.moduleId) ? constants.PHO.STATUS.COMPLETED : constants.PHO.STATUS.TODO;
          if (item['status'] === constants.PHO.STATUS.TODO) {
            if (index === 0) {
              if (modules[0].status === constants.PHO.STATUS.COMPLETED) {
                item['status'] = constants.PHO.STATUS.IN_PROGRESS;
                setActiveMenu(item);
              }
            } else if (responseModules[index - 1].status === constants.PHO.STATUS.COMPLETED) {
              item['status'] = constants.PHO.STATUS.IN_PROGRESS;
              setActiveMenu(item);
            }
          }
          item?.contents?.map((cnts: IContent, Index: number) => {
            let userInputIdsList = userInputIds.map((id: string) => {
              return id.substring(id.indexOf('#') + 1, id.indexOf('=')).trim();
            });
            cnts['Index'] = Index;
            cnts['answer'] = typeof cnts.answer === 'string' ? JSON.parse(Base64.decode(cnts.answer)) : cnts.answer;
            cnts['question_completed'] = ids.includes(cnts.contentId) || userInputIdsList.includes(cnts.contentId);
            cnts['contentData'] = cnts.contentData.map((content: string) => content.trim());
            cnts['answer'] = Array.isArray(cnts.answer) ? cnts.answer.map((content: string) => content.trim()) : cnts.answer;
            cnts['status'] =
              ids.includes(cnts.contentId) || userInputIdsList.includes(cnts.contentId)
                ? constants.PHO.STATUS.COMPLETED
                : constants.PHO.STATUS.TODO;
            userInputIds.forEach((id: string) => {
              if (cnts['status'] === constants.PHO.STATUS.COMPLETED) {
                let InDex: any = id.split('=')[1];
                cnts['input_value'] = cnts.contentData[InDex];
              }
            });
            if (cnts['status'] === constants.PHO.STATUS.TODO && item['status'] !== constants.PHO.STATUS.TODO) {
              if (Index === 0) {
                cnts['status'] = constants.PHO.STATUS.IN_PROGRESS;
                item['status'] = constants.PHO.STATUS.IN_PROGRESS;
                setActiveMenu(item);
                setActiveSubMenu(cnts);
              } else if (item?.contents[Index - 1].status === constants.PHO.STATUS.COMPLETED) {
                cnts['status'] = constants.PHO.STATUS.IN_PROGRESS;
                item['status'] = constants.PHO.STATUS.IN_PROGRESS;
                setActiveMenu(item);
                setActiveSubMenu(cnts);
              }
            }
            return cnts;
          });
          modules.push(item);
        });
        setMenuItems(modules);
        setModuleData(modules);
        setIsLoading(false);
      })
      .catch((err: any) => {
        props.snackbarShowMessage(`${err?.data?.message}`, 'error');
        setIsLoading(false);
      });
  };

  const updateModuleStatus = (userInput?: string) => {
    if (activeMenu.status === constants.PHO.STATUS.COMPLETED) {
      if (!activeSubMenu) {
        /* istanbul ignore next */
        setActiveMenu(menuItems[activeMenu.Index + 1]); /* istanbul ignore next */
        if (menuItems[activeMenu.Index + 1].contents.length) {
          setActiveSubMenu(menuItems[activeMenu.Index + 1].contents[0]);
        }
      }
      if (activeSubMenu && activeSubMenu.status === constants.PHO.STATUS.COMPLETED) {
        if (activeMenu.contents.length === activeSubMenu.Index + 1) {
          setActiveMenu(menuItems[activeMenu.Index + 1]);
          setActiveSubMenu(menuItems[activeMenu.Index + 1].contents[0]);
        } else {
          setActiveSubMenu(activeMenu.contents[activeSubMenu.Index + 1]);
        }
      }
      return;
    }
    if (
      activeMenu.status === constants.PHO.STATUS.IN_PROGRESS &&
      activeSubMenu &&
      activeSubMenu.status === constants.PHO.STATUS.COMPLETED
    ) {
      setActiveSubMenu(activeMenu.contents[activeSubMenu.Index + 1]);
      return;
    }
    setIsLoading(true);
    let completedIds: Array<string> = activeSubMenu
      ? activeMenu.contents
          .slice(0, activeSubMenu.Index + 1)
          .filter((item: IContent) => item.contentType !== constants.INPUT_TYPES.USER_INPUT_SINGLE_SELECT)
          .map(({ contentId }: any) => contentId)
      : [];
    let phoStatus: string = constants.PHO.STATUS.IN_PROGRESS;
    if (activeMenu && activeSubMenu && activeMenu.moduleName === menuItems[moduleData.length - 1].moduleName) {
      phoStatus =
        activeMenu.contents[activeMenu.contents.length - 1].Index === activeSubMenu.Index
          ? constants.PHO.STATUS.COMPLETED
          : constants.PHO.STATUS.IN_PROGRESS;
    } else {
      phoStatus =
        activeMenu.moduleName === menuItems[moduleData.length - 1].moduleName
          ? constants.PHO.STATUS.COMPLETED
          : constants.PHO.STATUS.IN_PROGRESS;
    }

    let payload = {
      phoId: phoId,
      candidateId: candidateId,
      applicationId: applicationId,
      moduleId: activeMenu.moduleId, //content#contentid
      phoProgress: `[${completedIds.toString()}]`, //input value
      phoStatus: phoStatus,
    };

    if (userInput) {
      payload['moduleId'] = `${constants.CONTENT_PREFIX}${activeSubMenu.contentId}`;
      payload['phoProgress'] = userInput;
    }

    //TODO::
    PhoService.postCandidatePhoProgress(payload)
      .then((res: any) => {
        if (activeMenu.moduleName === menuItems[moduleData.length - 1].moduleName) {
          if (!activeSubMenu) {
            onComplete();
          } else if (
            activeSubMenu &&
            activeSubMenu.Index === activeMenu.contents[activeMenu.contents.length - 1].Index
          ) {
            onComplete();
          } else {
            getProgress(menuItems.slice(1, menuItems.length));
          }
        } else {
          getProgress(menuItems.slice(1, menuItems.length));
        }
      })
      .catch((err: any) => {
        props.snackbarShowMessage(`${err?.data?.message}`, 'error');
      });
  };

  const navigateToPlayerScreen = () => {
    setIsLoading(true);
    let hiringStagebody = {
      candidatesInput: [{ id: applicationId }].map((candidate) => {
        return { candidateId: candidate.id, hiringStage: constants.PHO.STAGES.IN_PROGRESS };
      }),
      modifiedBy: candidateId,
      actionType: constants.PHO.UPDATE_CANDIDATE_ENTITY_OPERATION,
    };

    PhoService.postCandidateHiringStage(hiringStagebody)
      .then((res) => {
        PhoService.postCandidatePhoProgress({
          phoId: phoId,
          candidateId: candidateId,
          applicationId: applicationId,
          moduleId: constants.CUSTOM_MODULES.PHO_CUSTOM_WELCOME_MODULE,
          phoProgress: '[]',
          phoStatus: 'IN_PROGRESS',
        })
          .then((res: any) => {
            getProgress(menuItems.slice(1, menuItems.length));
          })
          .catch((err: any) => {
            props.snackbarShowMessage(`${err?.data?.message}`, 'error');
          });
      })
      .catch((err) => {
        props.snackbarShowMessage(`${err?.data?.message}`, 'error');
      });
  };

  const goBack = () => {
    if (activeSubMenu) {
      if (activeSubMenu.Index === 0) {
        setActiveMenu(menuItems[activeMenu.Index - 1]);
        if (menuItems[activeMenu.Index - 1].contents.length) {
          setActiveSubMenu(
            menuItems[activeMenu.Index - 1].contents[menuItems[activeMenu.Index - 1].contents.length - 1]
          );
        } else {
          setActiveSubMenu(null);
        }
      } else {
        setActiveSubMenu(activeMenu.contents[activeSubMenu.Index - 1]);
      }
    } else {
      /* istanbul ignore next */
      setActiveMenu(menuItems[activeMenu.Index - 1]); /* istanbul ignore next */
      if (menuItems[activeMenu.Index - 1].contents.length) {
        setActiveSubMenu(menuItems[activeMenu.Index - 1].contents[menuItems[activeMenu.Index - 1].contents.length - 1]);
      } else {
        setActiveSubMenu(null);
      }
    }
  };

  return (
    <View alignItems="center" style={{ zIndex: isLoading ? 4 : 1, overflow: 'hidden', height: '100%', width: '100%' }}>
      {isLoading && (
        <View
          height="90%"
          width="100%"
          style={{
            position: 'absolute',
            zIndex: 9999,
            overflow: 'hidden',
            background: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FullPageCenteredSpinner size="m" message="" />
        </View>
      )}
        {(deviceResolution.m || deviceResolution.s) && !enableConfirm && activeMenu && (
          <>
            <Flex flexDirection="row" width="100%" style={{ height: '60px', borderBottom: '1px solid' }}>
              <Flex flexDirection="column" flex={8} padding="0.5rem" style={{ paddingLeft: '1rem' }}>
                <Row flex={activeMenu.moduleName !== constants.PHO.MODULES.FEEDBACK ? 5 : 10} alignItems="center">
                  <Text fontSize={'T200'} fontWeight="bold">
                    {activeMenu?.moduleName}{' '}
                    {activeSubMenu
                      ? ' / ' + activeSubMenu?.contentName + ` ${activeSubMenu.Index ? activeSubMenu.Index + 1 : 1}`
                      : ''}
                  </Text>
                </Row>
                {activeMenu.moduleName !== constants.PHO.MODULES.FEEDBACK &&
                  activeMenu.moduleName !== constants.PHO.MODULES.WELCOME && (
                    <Row flex={5} alignItems="center">
                      <Col width={'60%'}>
                        <ProgressBar
                          aria-labelledby="status-indicator-1"
                          progress={activeMenu?.Index ? (activeMenu.Index - 1) / (menuItems.length - 1) : 0}
                          status={Status.Positive}
                        />
                      </Col>
                    </Row>
                  )}
              </Flex>
              <Flex flexDirection="row" flex={2} justifyContent="center" alignItems="center" style={{ zIndex: 2 }}>
                {isOpenMobileMenu ? (
                  <View
                    onClick={() => {
                      setIsOpenMobileMenu(false);
                    }}
                  >
                    <IconChevronUp color="primary70" />
                  </View>
                ) : (
                  <View
                    onClick={() => {
                      setIsOpenMobileMenu(true);
                    }}
                  >
                    <IconChevronDown color="primary70" />
                  </View>
                )}
              </Flex>
            </Flex>
            {isOpenMobileMenu && !enableConfirm && (
              <Flex
                flexDirection="row"
                onClick={() => {
                  setIsOpenMobileMenu(false);
                }}
                style={{
                  position: 'absolute',
                  background: 'transparent',
                  height: '100%',
                  width: '100%',
                  zIndex: 9,
                  boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
                }}
              >
                <SideNavBar
                  hideSelection={enableRestart}
                  modules={menuItems}
                  isMobile={true}
                  activeMenu={activeMenu}
                  activeSubMenu={activeSubMenu}
                />
              </Flex>
            )}
          </>
        )}
        <Row style={{ zIndex: 2, height: '100%', width: '100%' }} className="container">
          {(deviceResolution.l || deviceResolution.xl) && !enableConfirm && (
            <Col width="26%" style={{ height: '100vh' }} className="sidenav">
              <SideNavBar
                hideSelection={enableRestart}
                modules={menuItems}
                isMobile={false}
                activeMenu={activeMenu}
                activeSubMenu={activeSubMenu}
              />
            </Col>
          )}
          {enableConfirm && <ConfirmComponent />}
          {enableRestart && !enableConfirm && (
            <Col
              width={deviceResolution.l || deviceResolution.xl ? '80%' : '100%'}
              gridGap={'S300'}
              style={
                deviceResolution.l || deviceResolution.xl
                  ? { padding: '5rem 9rem', zIndex: 4 }
                  : { zIndex: 4, padding: '1rem', paddingBottom: '70px' }
              }
            >
              <Row>
                <Col>
                  <Text fontSize="T500" fontWeight="medium" color="primary70">
                    {t('PHO-greeting-casual', 'Hey,', { name: candidateName })}
                  </Text>
                  <Text fontSize="T500" fontWeight="medium" color="neutral90">
                    {t('PHO-retake-orientation-intro', "You've completed your Orientation")}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Text fontSize="T300" fontWeight="regular" color="neutral90">
                  {t(
                    'PHO-retake-orientation-summary',
                    "You've learned about the advantages of working for staffing partner at site and understand how a day looks like in the life of an associate."
                  )}
                  {t(
                    'PHO-retake-orientation-instructions',
                    "You can re-take the orientation if you need a refresher, however, you'll reset your completion status and have to complete this orientation again in one session."
                  )}
                </Text>
              </Row>
              <Row>
                <Button variant={ButtonVariant.Tertiary} onClick={reset} id="restart-button">
                  {t('PHO-btn-restart-orientation', 'Restart orientation')}
                </Button>
              </Row>
            </Col>
          )}
          {activeMenu &&
            activeMenu?.moduleId === constants.CUSTOM_MODULES.PHO_CUSTOM_WELCOME_MODULE &&
            !enableRestart &&
            !enableConfirm && (
              <Col
                className="sidenav"
                width={deviceResolution.l || deviceResolution.xl ? '80%' : '100%'}
                gridGap={'S300'}
                style={
                  deviceResolution.l || deviceResolution.xl
                    ? { padding: '5rem 9rem', zIndex: 4 }
                    : { zIndex: 4, padding: '1rem', paddingBottom: '70px' }
                }
              >
                <Row>
                  <Col>
                    <Text fontSize="T500" fontWeight="medium" color="primary70">
                      {t('PHO-greeting-formal', 'Hey,', { name: candidateName })}
                    </Text>
                    <Text fontSize="T500" fontWeight="medium" color="neutral90">
                      {t(
                        'PHO-welcome-module-heading',
                        "Welcome to the Warehouse Associate Job's Pre-Hire Orientation."
                      )}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Text fontSize="T300" fontWeight="regular" color="neutral90">
                    {t(
                      'PHO-welcome-module-intro',
                      "This session aims to provide insights into the job's nature and offer a glimpse into the life of an associate deployed at various client sites such as Amazon or others."
                    )}
                  </Text>
                </Row>
                {moduleData && !!moduleData.length && (
                  <Row style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text fontSize={'T300'}>
                      {t('PHO-welcome-module-contents', 'What can you expect from this orientation?')}
                    </Text>
                    <ul>
                      {moduleData.map(
                        (item: any, index: number) =>
                          item.moduleId !== constants.CUSTOM_MODULES.PHO_CUSTOM_WELCOME_MODULE && (
                            <li key={index}>
                              <Text fontSize="T300" fontWeight="regular">
                                {item.moduleName}
                              </Text>
                            </li>
                          )
                      )}
                    </ul>
                  </Row>
                )}
                <Row>
                  <Text fontSize={'T300'}>
                    {t('PHO-note', 'Note: ', {
                      information: t(
                        'PHO-welcome-module-disclaimer',
                        "If selected for the job, you will be employed directly by the staffing agency that listed the job. The nature of agency employment allows for the possibility of being assigned to any client of the staffing agency at the agency's sole discretion."
                      ),
                      b: (chunk: string) => <strong>{chunk}</strong>,
                    })}
                  </Text>
                </Row>
                <Row>
                  <MessageBanner icon={<></>}>
                    <Text fontSize={'T300'}>
                      {' '}
                      <strong>
                        {t('PHO-welcome-module-estimation-p1', 'Estimated time to complete: 10 minutes.')}{' '}
                      </strong>
                      {t('PHO-welcome-module-estimation-p2', 'This orientation has to be taken in one session.')}
                    </Text>
                  </MessageBanner>
                </Row>
                <Row>
                  <Button
                    variant={ButtonVariant.Primary}
                    style={{
                      padding: '0.5rem 6rem',
                      marginTop: deviceResolution.l || deviceResolution.xl ? '22px' : '6px',
                      fontWeight: 'normal',
                      zIndex: '5',
                    }}
                    onClick={() => {
                      navigateToPlayerScreen();
                    }}
                    id="get-started-button"
                  >
                    {t('PHO-btn-get-started', 'Get Started')}
                  </Button>
                </Row>
              </Col>
            )}
          {activeMenu &&
            activeMenu?.moduleId !== constants.CUSTOM_MODULES.PHO_CUSTOM_WELCOME_MODULE &&
            !enableConfirm &&
            !enableRestart && (
              <ModulePage
                goBack={goBack}
                activeMenu={activeMenu}
                activeSubMenu={activeSubMenu}
                state={menuItems}
                updateModuleStatus={updateModuleStatus}
              />
            )}
        </Row>
        <img
          src={FooterImgSVG}
          width="100%"
          alt={'footerImg'}
          style={{ zIndex: 3, position: 'fixed', display: 'block', bottom: 0 }}
        />
    </View>
  );
}

export default withAlertSnackBar(HomePage);
